<template>
  <div>
    <v-row
      class="mt-3 pt-5"
      :style="`color: ${template.colors.inline_primary_text};`"
    >
      <v-col cols="12" align-self="center">
        <v-btn text to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
          ZURÜCK
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-xl">
          <v-toolbar :color="template.colors.primary" dark flat>
            <v-icon large class="mr-2">mdi-hand-wash</v-icon>

            <v-toolbar-title>HYGIENEKONZEPT</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn text large @click="save()">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              SPEICHERN
            </v-btn>
          </v-toolbar>
        </v-card>
        <keep-alive>
          <v-row justify="center">
            <v-col cols="12">
              <v-card
                flat
                :color="hygienekonzept.gg_regel ? template.colors.blocks : ''"
                :light="hygienekonzept.gg_regel"
                class="px-2 py-0 rounded-xl"
              >
                <v-row justify="center" class="my-0">
                  <v-col cols="12">
                    <v-switch
                      inset
                      v-model="hygienekonzept.gg_regel"
                      hide-details
                      class="my-0"
                      @click="change_regel('gg')"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.gg_regel ? 'font-weight-bold' : ''
                          "
                          :style="
                            hygienekonzept.gg_regel
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          2G-Regel
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="hygienekonzept.gg_regel">
                  <v-col cols="12">
                    <span>
                      <b>Alle Personen</b>
                      (Zuschauer & Sportler) müssen
                      <b>immunisiert</b>
                      sein (vollständig geimpft oder genesen)
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.ggplus_regel ? template.colors.blocks : ''
                "
                :light="hygienekonzept.ggplus_regel"
                class="px-2 py-0 rounded-xl"
              >
                <v-row justify="center" class="my-0">
                  <v-col cols="12">
                    <v-switch
                      inset
                      v-model="hygienekonzept.ggplus_regel"
                      hide-details
                      class="my-0"
                      @click="change_regel('ggplus')"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.ggplus_regel
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.ggplus_regel
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          2G-PLUS-Regel
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="hygienekonzept.ggplus_regel">
                  <v-col cols="12">
                    <span>
                      <b>Alle Personen</b>
                      (Zuschauer & Sportler) müssen
                      <b>immunisiert</b>
                      (vollständig geimpft oder genesen) und
                      <b>getestet</b>
                      sein (Antigen-Schnelltest -> nicht älter als 24h /
                      PCR-Test -> nicht älter als 48h)
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.testpflicht ? template.colors.blocks : ''
                "
                :light="hygienekonzept.testpflicht"
                class="px-2 py-0 rounded-xl"
              >
                <v-row justify="center" class="my-0">
                  <v-col cols="12">
                    <v-switch
                      inset
                      v-model="hygienekonzept.testpflicht"
                      hide-details
                      class="my-0"
                      @click="change_regel('ggg')"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.testpflicht ? 'font-weight-bold' : ''
                          "
                          :style="
                            hygienekonzept.testpflicht
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          3G-Regel
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="hygienekonzept.testpflicht">
                  <v-col cols="12">
                    <span>
                      <b>Alle Personen</b>
                      (Zuschauer & Sportler) müssen
                      <b>immunisiert</b>
                      (vollständig geimpft oder genesen) oder
                      <b>getestet</b>
                      sein (Antigen-Schnelltest -> nicht älter als 24h /
                      PCR-Test -> nicht älter als 48h)
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.custom_header.activated
                    ? template.colors.blocks
                    : ''
                "
                :light="hygienekonzept.custom_header.activated"
                class="px-2 py-0 rounded-xl"
              >
                <v-row justify="center" class="my-0">
                  <v-col cols="12">
                    <v-switch
                      inset
                      v-model="hygienekonzept.custom_header.activated"
                      hide-details
                      class="my-0"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.custom_header.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.custom_header.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          INDIVIDUELLER TEXT
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row
                    justify="center"
                    v-if="hygienekonzept.custom_header.activated"
                  >
                    <v-col cols="12">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.custom_header.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.custom_header.text"
                      ></v-textarea>
                      <v-text-field
                        label="Individueller Link"
                        hint="https://example.com"
                        persistent-hint
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.custom_header.link"
                      ></v-text-field>
                      <v-text-field
                        label="Link-Name"
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.custom_header.linkname"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.abstand.activated ? template.colors.blocks : ''
                "
                :light="hygienekonzept.abstand.activated"
                class="px-2 rounded-xl"
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      inset
                      v-model="hygienekonzept.abstand.activated"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.abstand.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.abstand.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          ABSTAND
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row
                    justify="center"
                    v-if="hygienekonzept.abstand.activated"
                  >
                    <v-col cols="4" align-self="center">
                      <v-img src="/img/covid-icons/abstand.png"></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.abstand.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        v-model="hygienekonzept.abstand.text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.bereiche.activated
                    ? template.colors.blocks
                    : ''
                "
                :light="hygienekonzept.bereiche.activated"
                class="px-2 rounded-xl"
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      inset
                      v-model="hygienekonzept.bereiche.activated"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.bereiche.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.bereiche.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          BEREICHE & MARKIERUNGEN
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row
                    justify="center"
                    v-if="hygienekonzept.bereiche.activated"
                  >
                    <v-col cols="4" align-self="center">
                      <v-img src="/img/covid-icons/bereiche.png"></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.bereiche.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        v-model="hygienekonzept.bereiche.text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.desinfektion.activated
                    ? template.colors.blocks
                    : ''
                "
                :light="hygienekonzept.desinfektion.activated"
                class="px-2 rounded-xl"
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      inset
                      v-model="hygienekonzept.desinfektion.activated"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.desinfektion.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.desinfektion.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          DESINFEKTION
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row
                    justify="center"
                    v-if="hygienekonzept.desinfektion.activated"
                  >
                    <v-col cols="4" align-self="center">
                      <v-img src="/img/covid-icons/haende.png"></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.desinfektion.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        v-model="hygienekonzept.desinfektion.text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.kontakte.activated
                    ? template.colors.blocks
                    : ''
                "
                :light="hygienekonzept.kontakte.activated"
                class="px-2 rounded-xl"
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      inset
                      v-model="hygienekonzept.kontakte.activated"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.kontakte.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.kontakte.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          KONTAKT
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row
                    justify="center"
                    v-if="hygienekonzept.kontakte.activated"
                  >
                    <v-col cols="4" align-self="center">
                      <v-img src="/img/covid-icons/no_handshake.png"></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.kontakte.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        v-model="hygienekonzept.kontakte.text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.maske.activated ? template.colors.blocks : ''
                "
                :light="hygienekonzept.maske.activated"
                class="px-2 rounded-xl"
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      inset
                      v-model="hygienekonzept.maske.activated"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.maske.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.maske.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          MASKE
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row justify="center" v-if="hygienekonzept.maske.activated">
                    <v-col cols="4" align-self="center">
                      <v-img src="/img/covid-icons/maske.png"></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.maske.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        v-model="hygienekonzept.maske.text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                :color="
                  hygienekonzept.symptome.activated
                    ? template.colors.blocks
                    : ''
                "
                :light="hygienekonzept.symptome.activated"
                class="px-2 rounded-xl"
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      inset
                      v-model="hygienekonzept.symptome.activated"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.symptome.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.symptome.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          SYMPTOME
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row
                    justify="center"
                    v-if="hygienekonzept.symptome.activated"
                  >
                    <v-col cols="4" align-self="center">
                      <v-img src="/img/covid-icons/healthy.png"></v-img>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.symptome.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        v-model="hygienekonzept.symptome.text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-card
                flat
                :color="
                  hygienekonzept.custom_footer.activated
                    ? template.colors.blocks
                    : ''
                "
                :light="hygienekonzept.custom_footer.activated"
                class="px-2 rounded-xl"
              >
                <v-row justify="center">
                  <v-col cols="12" class="pt-0">
                    <v-switch
                      inset
                      v-model="hygienekonzept.custom_footer.activated"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            hygienekonzept.custom_footer.activated
                              ? 'font-weight-bold'
                              : ''
                          "
                          :style="
                            hygienekonzept.custom_footer.activated
                              ? `color: ${template.colors.primary};`
                              : ''
                          "
                        >
                          INDIVIDUELLER TEXT 2
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row
                    justify="center"
                    v-if="hygienekonzept.custom_footer.activated"
                  >
                    <v-col cols="12">
                      <v-text-field
                        label="Titel"
                        hide-details
                        outlined
                        class="mb-3"
                        v-model="hygienekonzept.custom_footer.title"
                      ></v-text-field>
                      <v-textarea
                        label="Text"
                        hide-details
                        outlined
                        v-model="hygienekonzept.custom_footer.text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </keep-alive>
        <v-row justify="center">
          <v-col cols="12">
            <v-btn
              block
              large
              :color="template.colors.primary"
              class="rounded-xl"
              @click="save()"
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              SPEICHERN
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="error" multi-line color="danger">
      {{ errortext }}
    </v-snackbar>
    <v-snackbar v-model="success" multi-line color="success">
      {{ successtext }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'Hygienekonzept-bearbeiten',
  data() {
    return {
      verein: {
        name: '',
        maxzuschauer: '',
        alias: '',
        logo: '',
        template: '',
      },
      logo: {
        dialog: false,
        uploading: false,
        imageData: '',
      },
      designs: [
        'Standard-Design',
        'Blau',
        'Dunkelgrün',
        'Gelb',
        'Hellgrün',
        'Rot',
        'Schwarz',
        'Weiss',
      ],
      teams: [],
      rollen: ['Trainer', 'Vorstand'],
      created: false,
      creating: false,
      nameok: '',
      nameerror: '',
      zsok: '',
      zserror: '',
      linkok: '',
      linkexist: '',
      valid: false,
      error: false,
      errortext: '',
      success: false,
      successtext: '',
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      characters: 'a-z,A-Z,0-9',
      tab: 0,
      hygienekonzept: {
        gg_regel: false,
        ggplus_regel: false,
        testpflicht: false,
        ggg_regel: {
          title: '3G-Regel',
          text:
            'Es ist zwingend ein negativer COVID-19 Test (nicht älter als 48h), ein Nachweis über eine vollständige COVID-19 Impfung oder ein Nachweis über eine Genesung von COVID-19 (nicht älter als 6 Monate).',
        },
        custom_header: {
          activated: false,
          title: '',
          text: '',
          link: '',
          linkname: '',
        },
        custom_footer: {
          activated: false,
          title: '',
          text: '',
        },
        abstand: {
          activated: true,
          title: 'Halte Abstand',
          text: 'Achte auf den Mindestabstand - auch im Zuschauerbereich.',
        },
        bereiche: {
          activated: true,
          title: 'Beachte die Kennzeichnungen',
          text:
            'Beachte die markierten Wege und halte dich nur in den gekennzeichneten Bereichen auf.',
        },
        desinfektion: {
          activated: true,
          title: 'Hände desinfizieren',
          text:
            'Desinfiziere deine Hände beim Betreten & Verlassen des Geländes oder wasche dir für min. 20 Sekunden deine Hände',
        },
        kontakte: {
          activated: true,
          title: 'Vermeide Kontakt',
          text:
            'Verzichte auf übliche Begrüßungsrituale und Körperkontakt zu anderen Personen',
        },
        maske: {
          activated: true,
          title: 'Maskenpflicht',
          text:
            'Auf dem gesamten Sportgelände ist das Tragen eines Mund- & Nasenschutzes vorgeschrieben.',
        },
        symptome: {
          activated: true,
          title: 'Fühlst du dich fit?',
          text: 'Bitte bleibe zu Hause, wenn du dich krank fühlst.',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.user.data.verein) {
      if (this.user.data.hygienekonzept) {
        if (!this.user.data.hygienekonzept.ggg_regel) {
          this.user.data.hygienekonzept.ggg_regel = this.hygienekonzept.ggg_regel
        }
        this.hygienekonzept = this.user.data.hygienekonzept
        if (!this.hygienekonzept.testpflicht) {
          this.hygienekonzept.testpflicht = false
        }
        if (!this.hygienekonzept.gg_regel) {
          this.hygienekonzept.gg_regel = false
        }
        if (!this.hygienekonzept.ggplus_regel) {
          this.hygienekonzept.ggplus_regel = false
        }
      }
    }
  },
  methods: {
    change_regel(val) {
      if (this.hygienekonzept.gg_regel && val == 'gg') {
        this.hygienekonzept.ggplus_regel = false
        this.hygienekonzept.testpflicht = false
      }
      if (this.hygienekonzept.ggplus_regel && val == 'ggplus') {
        this.hygienekonzept.gg_regel = false
        this.hygienekonzept.testpflicht = false
      }
      if (this.hygienekonzept.testpflicht && val == 'ggg') {
        this.hygienekonzept.gg_regel = false
        this.hygienekonzept.ggplus_regel = false
      }
    },
    save() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          hygienekonzept: this.hygienekonzept,
        })
        .then(() => {
          this.success = true
          this.successtext =
            'Dein Hygienekonzept wurde erfolgreich gespeichert!'
        })
    },
  },
}
</script>
